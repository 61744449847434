<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('BREADCRUMBS.CONTRACT_REG') }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <div class="col-4 pl-0">
              <v-combobox
                v-model="combobox"
                v-debounce:400ms="myFn"
                dense
                :items="allClientNames"
                item-text="full_name1"
                item-value="id"
                :label="$t('INPUTS.CLIENT_NAME')"
                outlined
              ></v-combobox>
            </div>

            <div class="col-4">
              <v-select
                :error-messages="paymentErrors"
                v-model="payment_type"
                dense
                :items="items2"
                item-text="name"
                item-value="value"
                :label="$t('INPUTS.PAYMENT_TYPE')"
                outlined
                @input="$v.payment_type.$touch()"
                @blur="$v.payment_type.$touch()"
              ></v-select>
            </div>
            <div class="col-4 pr-0">
              <v-select
                @change="changed"
                v-model="typeOfContract"
                dense
                item-text="state"
                outlined
                item-value="abbr"
                :items="items"
                :label="$t('TABLE_HEADER.CONTRACT_TYPE')"
              ></v-select>
            </div>
          </div>

          <div class="d-flex">
            <div class="col-4 pl-0">
              <!-- <label>Shartnoma nomeri</label>
            <input
              v-model="contractNumber"
              type="text"
              required
              class="form-control form-control-solid form-control-lg"
            /> -->
              <v-text-field
                v-model="contractNumber"
                :label="$t('TABLE_HEADER.CONTRACT_NUMBER')"
                dense
                outlined
              ></v-text-field>
            </div>
            <div class="col-4 pr-0" v-if="typeOfContract == 'SU'">
              <v-text-field
                v-model="amountOfContract"
                :label="$t('INPUTS.CONTRACT_AMOUNT')"
                outlined
                dense
                v-money="money"
              ></v-text-field>
            </div>
            <div class="col-4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="
                      $v.dateOFContract.$dirty &&
                      !$v.dateOFContract.required && [
                        'To\'ldirilishi shart bo\'lgan qator'
                      ]
                    "
                    :success="$v.dateOFContract.$dirty && dateOFContract"
                    :value="dateOFContract | dateFilter"
                    label="Shartnoma boshlanish sanasi"
                    readonly
                    dense
                    outlined
                    append-icon="event"
                    v-bind="attrs"
                    v-on="on"
                    @input="$v.dateOFContract.$touch()"
                    @blur="$v.dateOFContract.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateOFContract"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-4" v-if="typeOfContract == 'MU'">
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :error-messages="
                      $v.endOfContract.$dirty &&
                      !$v.endOfContract.required && [
                        'To\'ldirilishi shart bo\'lgan qator'
                      ]
                    "
                    :success="$v.endOfContract.$dirty && endOfContract"
                    :value="endOfContract | dateFilter"
                    label="Shartnoma tugash sanasi"
                    append-icon="event"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @input="$v.endOfContract.$touch()"
                    @blur="$v.endOfContract.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endOfContract"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="card-toolbar">
              <el-button
                :disabled="contractLoading"
                @click="save"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Send.svg-->
                  <svg
                    v-if="!contractLoading"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <path
                        d="M3,13.5 L19,12 L3,10.5 L3,3.7732928 C3,3.70255344 3.01501031,3.63261921 3.04403925,3.56811047 C3.15735832,3.3162903 3.45336217,3.20401298 3.70518234,3.31733205 L21.9867539,11.5440392 C22.098181,11.5941815 22.1873901,11.6833905 22.2375323,11.7948177 C22.3508514,12.0466378 22.2385741,12.3426417 21.9867539,12.4559608 L3.70518234,20.6826679 C3.64067359,20.7116969 3.57073936,20.7267072 3.5,20.7267072 C3.22385763,20.7267072 3,20.5028496 3,20.2267072 L3,13.5 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  <i v-else class="el-icon-loading"></i>
                  <!--end::Svg Icon--></span
                >
                {{ $t('FORM.SUBMIT') }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required, requiredIf } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      contractLoading: false,
      menu2: false,
      menu3: false,
      asd: '',
      clearable: true,
      money: {
        decimal: ',',
        thousands: ' ',
        precision: 0,
        masked: false
      },
      items: [
        { state: 'Muddatli', abbr: 'MU' },
        { state: 'Muddati cheklanmagan', abbr: 'MS' },
        { state: "Summaga bog'liq", abbr: 'SU' }
      ],
      items2: [
        { name: 'Shartnoma', value: 'CONT' },
        { name: 'Kelishuv', value: 'NAQD' }
      ],
      payment_type: '',
      combobox: '',
      comboboxid: '',
      typeOfContract: '',
      dateOFContract: '',
      contractNumber: '',
      endOfContract: '',
      amountOfContract: ''
    }
  },
  validations: {
    payment_type: {
      required
    },
    dateOFContract: {
      required
    },
    endOfContract: {
      required: requiredIf(function () {
        return ['MU'].includes(this.typeOfContract)
      })
    }
  },
  beforeCreate() {
    // this.$store.dispatch("getAllClientsName");
    // this.$store.dispatch("getAllContracts");
  },
  computed: {
    paymentErrors() {
      const errors = []
      if (!this.$v.payment_type.$dirty) return errors

      !this.$v.payment_type.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    allClientNames() {
      const data = this.$store.state.requests.allClientsName
      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.SALE.TITLE') },
      { title: this.$t('BREADCRUMBS.CONTRACT_REG') }
    ])
  },
  methods: {
    myFn(val) {
      const data = {
        name: val
      }
      this.$store.dispatch('clientSearch', data)
    },
    changed() {
      this.dateOFContract = null
      this.contractNumber = null
      this.endOfContract = null
      this.amountOfContract = null
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        Swal.fire({
          title: 'Xato',
          text: "Formanining qizil bilan belgilangan yo'lakchalarini to'ldiring",
          icon: 'error'
        })

        return
      }

      if (this.amountOfContract !== null) {
        this.amountOfContract = this.amountOfContract.replace(/\s/g, '')
      }
      const data = {
        payment_type: this.payment_type,
        contract_type: this.typeOfContract,
        end_date: this.endOfContract,
        start_date: this.dateOFContract,
        contract_number: this.contractNumber,
        amount: this.amountOfContract,
        parent_contract: null,
        client: this.combobox.id
      }
      this.contractLoading = true
      this.$store
        .dispatch('createContract', data)
        .then(() => {
          this.dateOFContract = null
          this.contractNumber = null
          this.endOfContract = null
          this.amountOfContract = null
          this.combobox = ''
          console.log('done!')
          this.contractLoading = false
        })
        .catch((err) => {
          this.contractLoading = false
          console.eror(err)
        })
    }
  }
}
</script>
